import axios from "axios";
import Swal from "sweetalert2";

export const baseURL = window.baseURL;
// export const baseURL = "https://ekyc.digitalsignature.com.bd:8080/DBS4/api";
// export const baseURL = "http://180.210.129.170:8080/DBS/api";

export const login = baseURL + "/login";
export const userEmailExist = baseURL + "/doesUserEmailExist";
export const doesUserPhoneExist = baseURL + "/doesUserPhoneExist";
export const otpgen = baseURL + "/otpgen";
export const otpverify = baseURL + "/otpverify";
export const eSignKyc = baseURL + "/nidmatch/eSignKyc2";
export const resetpassword = baseURL + "/resetpasswordinitiate";
export const resetpasswordNew = baseURL + "/resetpasswordapi";
export const changepasswordapi = baseURL + "/changepasswordapi";
export const facematchapi = baseURL + "/nidmatch/nidfacematch";
export const refrsh = baseURL + "/refreshcall";
export const apiinvalid = baseURL + "/sessioninvalid";

export const instance = axios.create({
  withCredentials: true,
  timeout: window.timeout,
});

instance.defaults.withCredentials = true;

window.addEventListener("onLine", () => {
  console.log("Online");
});
window.addEventListener("offLine", () => {
  console.log("Offline");
});
console.log("windownavigator", window.navigator.onLine);

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let config1 = {
      ...config,
      maxBodyLength: 104857600,
      maxContentLength: 104857600,
    };
    console.log("config", config1);
    return config1;
  },
  function (error) {
    // Do something with request error
    console.log("Some error req", error.message);
    errorCompute1(error.message, "/");
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.log("Some error res", error.message, error.header);
    errorCompute2(error.message, "/");
    return Promise.reject(error);
  }
);

export const errorCompute1 = (msg, redirection) => {
  Swal.fire({
    title: `<strong>${msg}</strong>`,
    icon: "error",
    html: `<b>You are currently offline. Please check the internet.</b>`,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    showConfirmButton: false,
    // confirmButtonText: "Okay",
    // confirmButtonAriaLabel: "Okay",
    cancelButtonText: "Okay",
    cancelButtonAriaLabel: "Okay",
  }).then(function (result) {
    if (result.value) {
      {
        if (redirection) {
          console.log("redirection", redirection);
          window.location = redirection;
        } else {
          window.location = "/login";
        }
      }
    }
  });
};

export const errorCompute2 = (msg, redirection) => {
  console.log("windownavigator", window.navigator.onLine);
  window.navigator.onLine
    ? Swal.fire({
        title: `<strong>${msg}</strong>`,
        icon: "error",
        html: `<b>Server seems unreachable. Please try again.</b>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        showConfirmButton: false,
        // confirmButtonText: "Okay",
        // confirmButtonAriaLabel: "Okay",
        cancelButtonText: "Okay",
        cancelButtonAriaLabel: "Okay",
      }).then(function (result) {
        if (result.value) {
          {
            if (redirection) {
              console.log("redirection", redirection);
              window.location = redirection;
            } else {
              window.location = "/login";
            }
          }
        }
      })
    : Swal.fire({
        title: `<strong>${msg}</strong>`,
        icon: "error",
        html: `<b>You are currently offline. Please check the internet.</b>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        showConfirmButton: false,
        // confirmButtonText: "Okay",
        // confirmButtonAriaLabel: "Okay",
        cancelButtonText: "Okay",
        cancelButtonAriaLabel: "Okay",
      }).then(function (result) {
        if (result.value) {
          {
            if (redirection) {
              console.log("redirection", redirection);
              window.location = redirection;
            } else {
              window.location = "/login";
            }
          }
        }
      });
};
